// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  //production: "https://7176-223-130-28-234.ngrok-free.app/",
  //production: "http://ec2-3-87-141-18.compute-1.amazonaws.com:3001/", //Live Server - AWS
  production: "http://srv407359.hstgr.cloud:3002/", //Live Server - Hostinger
  whatsappApi:"https://ec2-3-87-141-18.compute-1.amazonaws.com:3000/api/",
  //whatsappApi:"http://localhost:3000/api/",
  socketApi:"http://ec2-3-87-141-18.compute-1.amazonaws.com:10000/",
  //socketApi:"http://ec2-3-87-141-18.compute-1.amazonaws.com:10000/",
  sendEmailApi :"http://localhost:9000/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
